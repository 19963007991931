import React, { useState, useEffect } from "react";
import { createClient } from "@supabase/supabase-js";
import * as Sentry from "@sentry/react";
import PasswordValidator from 'password-validator';
import DOMPurify from "dompurify";
import logo from "../assets/logo.svg";
import PasswordResetForm from "./PasswordResetForm";
import VerifyButton from "./VerifyButton";
import Success from "./Success";
import Error from "./Error";
const supabaseUrl = "https://aiurtflfajcogrmfkeod.supabase.co";
const supabaseKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFpdXJ0ZmxmYWpjb2dybWZrZW9kIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Njk4Mjc5NzMsImV4cCI6MTk4NTQwMzk3M30.XqE2jr4sF2w31jSEfM7wwgPVFeZdZiA1O7Jiejg4PbA";
const supabase = createClient(supabaseUrl, supabaseKey);

var passwordSchema = new PasswordValidator();
passwordSchema
.is().min(8)                                   
.is().has().uppercase()                        
.is().has().lowercase()                       
.is().has().digits()                          
.is().has().symbols()  

function validatePassword(password) {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
    password
  );

  return (
    password.length >= minLength &&
    hasUpperCase &&
    hasLowerCase &&
    hasNumber &&
    hasSpecialChar
  );
}

export default function ResetPassword() {
  const [token, setToken] = useState(null);
  const [email, setEmail] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [brokenLink, setBrokenLink] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const email = DOMPurify.sanitize(decodeURIComponent(urlParams.get("email")));
    setToken(token);
    setEmail(email);
  }, []);
  

  useEffect(() => {
    if (showError) {
      setPassword("");
      setConfirmPassword("");
    }
  }, [showError]);

  const handleConfirmation = async (event) => {
    event.preventDefault();
    if (email && token) {
      try {
        const { data, error } = await supabase.auth.verifyOtp({
          email,
          token,
          type: "recovery",
        });
        if (error) throw error;
      } catch (error) {
        setShowError(true);
        setBrokenLink(true);
        Sentry.captureException(error);
        setErrorMessage(
          "Ungültiger oder abgelaufener Link. Bitte fordern Sie einen neuen Link zur Passwortvergabe an."
        );
      }
    } else {
      setShowError(true);
      setBrokenLink(true);
      setErrorMessage(
        "Ungültiger oder abgelaufener Link. Bitte fordern Sie einen neuen Link zur Passwortvergabe an."
      );
    }
    setClicked(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowSuccess(false);
    setShowError(false);
    if (password === confirmPassword) {
      if (validatePassword(password)) {
        try {
          const { data, error } = await supabase.auth.updateUser({
            password: password,
          });
          if (error) {
            throw error;
          }
          console.log(data);
          await supabase.auth.signOut();
          setShowSuccess(true);
          setTimeout(() => {
            window.location.href = "https://hia.diakonie-akademie.app/login";
          }, 3000);
        } catch (error) {
          console.error("Error:", error);
          Sentry.captureException(error);
          setShowError(true);
          setErrorMessage(
            "Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut."
          );
        }
      } else {
        setShowError(true);
        setErrorMessage(
          "Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten."
        );
      }
    } else {
      setShowError(true);
      setErrorMessage("Die Passwörter stimmen nicht überein.");
    }
  };
  
  

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto w-auto mb-8" src={logo} alt="DMO - Logo" />
          {showSuccess && <Success />}
          {showError && <Error errorMessage={errorMessage} />}
          {!clicked ? (
            <VerifyButton handleConfirmation={handleConfirmation} />
          ) : (
            !showSuccess &&
            clicked && !brokenLink && (
              <PasswordResetForm
                password={password}
                confirmPassword={confirmPassword}
                setPassword={setPassword}
                setConfirmPassword={setConfirmPassword}
                handleSubmit={handleSubmit}
                showSuccess={showSuccess}
                showError={showError}
                errorMessage={errorMessage}
              />
            )
          )}
        </div>
      </div>
    </>
  );
}
