import { useState, useEffect } from 'react';
import { ThumbUpIcon } from '@heroicons/react/20/solid';

export default function VerifyButton({ handleConfirmation }) {
  const [countdown, setCountdown] = useState(4);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (countdown > 0) {
      const timerId = setInterval(() => {
        setCountdown((count) => count - 1);
      }, 1000);
      return () => clearInterval(timerId);
    } else {
      setDisabled(false);
    }
  }, [countdown]);

  return (
    <div>
      <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
        Bitte bestätigen
      </h2>
      <button
        disabled={disabled}
        onClick={(e) => {
          setDisabled(true);
          setCountdown(3);
          handleConfirmation(e);
        }}
        className={`flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm hover:bg-[#0AB6FF] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${disabled ? 'bg-gray-400 text-white' : 'bg-[#009BDC] text-white hover:bg-[#0AB6FF]'} mt-10`}
      >
        {disabled ? `Warten Sie ${countdown} Sekunden` : 'Ich bin kein Roboter'}
      </button>
    </div>
  );
}