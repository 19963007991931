import { useState, useEffect } from 'react';
import { CheckIcon, EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import PasswordValidator from 'password-validator';

const schema = new PasswordValidator();
schema
  .is().min(8)
  .has().uppercase()
  .has().lowercase()
  .has().digits()
  .has().symbols();

function PasswordValidationChecklist({ password }) {
  const [lengthCheck, setLengthCheck] = useState(false);
  const [upperCaseCheck, setUpperCaseCheck] = useState(false);
  const [lowerCaseCheck, setLowerCaseCheck] = useState(false);
  const [numberCheck, setNumberCheck] = useState(false);
  const [symbolCheck, setSymbolCheck] = useState(false);

  useEffect(() => {
    const validationResult = schema.validate(password, { list: true });
    setLengthCheck(!validationResult.includes('min'));
    setUpperCaseCheck(!validationResult.includes('uppercase'));
    setLowerCaseCheck(!validationResult.includes('lowercase'));
    setNumberCheck(!validationResult.includes('digits'));
    setSymbolCheck(!validationResult.includes('symbols'));
  }, [password]);

  const passwordValidationRules = [
    { id: 1, content: 'Mindestens ein Kleinbuchstabe', check: lowerCaseCheck },
    { id: 2, content: 'Mindestens ein Großbuchstabe', check: upperCaseCheck },
    { id: 3, content: 'Mindestens eine Zahl', check: numberCheck },
    { id: 4, content: 'Mindestens ein Sonderzeichen', check: symbolCheck },
    { id: 5, content: 'Mindestens 8 Zeichen', check: lengthCheck },



  ];

  return (
    <div className="flow-root">
      <ul className="-mb-8">
        {passwordValidationRules.map((rule, ruleIdx) => (
          <li key={rule.id}>
            <div className="relative pb-8">
              {ruleIdx !== passwordValidationRules.length - 1 ? (
                <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={`h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white ${rule.check ? 'bg-[#009BDC]' : 'bg-gray-400'}`}
                  >
                    {rule.check ? <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" /> : <EllipsisHorizontalIcon className="h-5 w-5 text-white" aria-hidden="true" />}
                  </span>
                </div>
                <div className="min-w-0 flex-1 pt-1.5">
                  <p className="text-sm text-gray-500">
                    {rule.content}
                  </p>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default PasswordValidationChecklist;