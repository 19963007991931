import './App.css';
import ResetPassword from "./components/ResetPassword";
function App() {
  return (
    <div>
      <ResetPassword />
    </div>
  );
}

export default App;
